<template>
  <div>
    <v-dialog v-model="dialog" width="600px" persistent>
      <v-card>
        <v-footer
          padless
          tile
          class="msaBlue white--text"
          style="position: sticky; top: 0; z-index: 999"
        >
          <v-container fluid>
            <v-row align="center">
              <v-col class="text-h6 pl-6 grow"> Select Companies </v-col>
            </v-row>
          </v-container>
        </v-footer>
        <v-card-text class="mt-4">
          <v-row>
            <v-col>
              <SearchBar @search="onSearch" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-data-table
          mobile-breakpoint="1"
          :items="orgs"
          :server-items-length="orgCount"
          :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
          :options.sync="options"
          show-select
          :headers="headers"
          v-model="selected"
          checkbox-color="msaBlue"
          class="colored-table-check-box"
        >
        </v-data-table>
        <v-footer
          padless
          tile
          style="position: sticky; bottom: 0; z-index: 999"
          color="msaBlue"
        >
          <v-container fluid>
            <v-row>
              <v-col class="grow" align="right">
                <v-btn @click="close()" class="red white--text"> Cancel </v-btn>
              </v-col>
              <v-col class="shrink" align="right">
                <v-btn
                  @click="confirm()"
                  class="white msaBlue--text"
                  :disabled="selected.length == 0"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="msaBlue"
          class="white--text"
          v-bind="attrs"
          v-on="on"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="dialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Attach Company</span>
    </v-tooltip>
  </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'AddCompanies',
  components: {
    SearchBar,
  },
  watch: {
    options: {
      handler() {
        this.getCompanies();
      },
      deep: true,
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
      search: '',
      orgs: [],
      orgCount: 0,
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['name'],
        sortDesc: [false],
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onSearch(e) {
      this.search = e.trim();
      this.options.page = this.$constants.PAGINATION.DEFAULT_PAGE;
      this.getCompanies();
    },
    getCompanies() {
      this.search = this.search ?? '';

      const params = {
        page: this.options.page ? this.options.page : 1,
        perPage: this.options.itemsPerPage ? this.options.itemsPerPage : 10,
        search: this.search,
        sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : false,
        sortBy: this.options.sortBy ? this.options.sortBy[0] : 'name',
        labelId: this.$store.getters.selectedLabel.id,
        loaderText: 'loading...',
      };

      const url = 'get-available-companies-for-label?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.orgs = response.data.items;
          this.orgCount = response.data.count;
        })
        .catch((error) => error);
    },
    confirm() {
      const params = {
        companyIds: this.selected.map((s) => s.id),
        labelId: this.$store.getters.selectedLabel.id,
        loaderText: 'Saving...',
      };

      const url = 'add-companies-to-label?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('confirm');
          this.close();
        })
        .catch((error) => error);
    },
    close() {
      this.$emit('close');
      this.dialog = false;
      this.orgs = [];
    },
  },
};
</script>
<style scoped></style>
